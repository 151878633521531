import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InsuranceComponent from '../components/Insurance/insurance'

const Insurance = () => (
    <Layout>
        <Seo title="Patient Care" description="Check in for your virtual visit with Kids &amp; Teens Medical Group. Pediatrics serving patients in the Greater Los Angeles area . Call our office at 818-244-9595 or schedule an appointment online." />
        <InsuranceComponent />
    </Layout>
)

export default Insurance
