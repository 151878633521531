import React from "react"
import { Container, Row, Col, Accordion } from "react-bootstrap"
import Title from "../title"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"
import QrCode from "../../images/qr-code.png"

import * as styles from "../../styles/components/Insurance/insurance.module.scss"

export default function Insurance() {
  const intl = useIntl()

  return (
    <div>
      <Title title={intl.formatMessage({ id: "Insurance" })} />

      <Container fluid>
        {/* Image of QR */}
        <div className="mb-5 text-center my-5">
          <h2 className={styles.titleMain}>Belong To Another HMO IPA?</h2>
          <p>We can help you do an immediate transfer to our own network.</p>

          <p>Scan the QR code to book your call with our Transfer Team.</p>

          <a
            href="https://outlook.office365.com/owa/calendar/TransfersCalendar@ktdoctor.com/bookings/"
            target="_blank"
          >
            <img
              src={QrCode}
              alt="QR Code"
              style={{
                width: "160px",
              }}
            />
          </a>
        </div>

        <Row xs={1} md={2}>
          <Col className={styles.wrapper}>
            <div className={`${styles.content} mx-auto p-5`}>
              <h1 className={`${styles.title}`}>
                {intl.formatMessage({ id: "InsuranceTitle1" })}
              </h1>
              <p className={`${styles.text} my-4`}>
                {intl.formatMessage({ id: "Insurance1" })}
                {""}
                <a
                  className={styles.link}
                  href="milto:customerservice@ktdoctor.com"
                >
                  <strong>customerservice@ktdoctor.com</strong>
                </a>
              </p>
              <p>{intl.formatMessage({ id: "Insurance2" })}</p>
            </div>
          </Col>

          <Col className="p-0">
            <StaticImage
              src="../../images/insurance/insurance-bg.jpg"
              layout="full_width"
              className={styles.img}
              alt=""
            />
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row xs={1} md={2}>
          <Col className="p-0">
            <StaticImage
              src="../../images/insurance/insurance-1.jpg"
              layout="full_width"
              className={styles.img}
              alt=""
            />
          </Col>

          <Col className={styles.wrapper2}>
            <div className={`${styles.content} mx-auto p-5`}>
              <h1 className={`${styles.title}`}>
                {intl.formatMessage({ id: "InsuranceTitle2" })}
              </h1>
              <p className={`${styles.text} my-4`}>
                {intl.formatMessage({ id: "Insurance5" })}
                <br />
                <a className={styles.link} href="tel:8183615437">
                  {" "}
                  <strong>(818) 361-5437</strong>{" "}
                </a>
                {intl.formatMessage({ id: "Insurance5Sub" })}
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="my-5">
        <Row className="g-5 align-items-center">
          <Col>
            <h2 className={`h1 text-center mb-4 mt-4 ${styles.title3}`}>
              <strong>{intl.formatMessage({ id: "InsuranceTitle3" })}</strong>
            </h2>
            <p className={`${styles.sub} text-center`}>
              {intl.formatMessage({ id: "InsuranceTitle3Sub" })}
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className={`${styles.c1} p-5`}>
        <Container>
          <Row xs={1} md={2} className={`my-5 g-5`}>
            <Col>
              <div className={`${styles.content3} p-5`}>
                <h1 className={`${styles.title2}`}>
                  {intl.formatMessage({ id: "InsuranceTitle4" })}
                </h1>
                <ul className={styles.list}>
                  <li>✔ Aetna</li>
                  <li>✔ Anthem Blue Cross and POS</li>
                  <li>✔ Blue Shield and POS</li>
                  <li>✔ California-Care</li>
                  <li>✔ Cigna</li>
                  <li>✔ Cigna- Disney Employees</li>
                  <li>✔ Health Net and POS</li>
                  <li>✔ LA Care</li>
                  <li>✔ Lakeside Medical Group</li>
                  <li>✔ Molina</li>
                  <li>✔ OPTUM</li>
                  <li>✔ Pacific-Care</li>
                  <li>✔ Regal Medical Group</li>
                  <li>✔ Serendib Healthways</li>
                  <li>✔ United Health Care</li>
                  <li>✔ Preferred IPA*</li>
                  <li>✔ Global Care*</li>
                  <li>✔ Allied Pacific*</li>
                  <li>✔ Community Family Care*</li>
                  <li>✔ Easy Care*</li>
                </ul>

                <p className={`${styles.text} mt-4`}>
                  <small>
                    {" "}
                    *{intl.formatMessage({ id: "MayRequireTransfer" })}
                  </small>
                </p>

                <p className={styles.text}>
                  {intl.formatMessage({ id: "Insurance5" })}
                  <a className={styles.link} href="tel:6265175766">
                    {" "}
                    <strong>626-517-5766</strong>
                  </a>{" "}
                  {intl.formatMessage({ id: "Insurance5Sub" })}
                </p>
              </div>
            </Col>

            <Col>
              <div className={`${styles.content3} p-5`}>
                <h1 className={`${styles.title2}`}>
                  {intl.formatMessage({ id: "InsuranceTitle5" })}
                </h1>
                <ul className={styles.list}>
                  <li>✔ Aetna PPO/POS and Managed Choice</li>
                  <li>✔ Ameriben</li>
                  <li>✔ Anthem Blue Cross of CA</li>
                  <li>✔ Blue Shield Of CA</li>
                  <li>✔ BlueCard Plans/POS</li>
                  <li>
                    ✔ Cigna PPO, Open Access Plus,Disney and International
                    Global
                  </li>
                  <li>✔ Directors Guild </li>
                  <li>✔ Great West Life</li>
                  <li>✔ Humana</li>
                  <li>✔ HMA</li>
                  <li>✔ Health Net PPO/POS</li>
                  <li>✔ Keenan-Prime HealthCare</li>
                  <li>✔ Motion Picture/ MPI</li>
                  <li>✔ Screen Actors Guild/SAG</li>
                  <li>✔ Tricare Prime/ChampVA</li>
                  <li>✔ Writers Guild</li>
                  <li>✔ United Healthcare PPO/POS</li>
                </ul>
                <p className={`${styles.text} mt-4`}>
                  <strong>
                    {intl.formatMessage({ id: "InsuranceTitle6Sub" })}{" "}
                    <a className={styles.link} href="tel:8183615437">
                      <strong>(818) 361-5437</strong>
                    </a>{" "}
                  </strong>
                </p>
                <p className={styles.text}>
                  {intl.formatMessage({ id: "Insurance5" })}{" "}
                  <a className={styles.link} href="tel:6265175766">
                    <strong>626-517-5766</strong>
                  </a>{" "}
                  {intl.formatMessage({ id: "Insurance5Sub" })}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* <Container fluid>
        <Row xs={1} md={2}>
          <Col className={styles.c1}>
            <Container className="p-5">
              <h1 className={`pt-5 ${styles.title2}`}>
                I Have Commercial HMO/IPA/Covered California
              </h1>
              <ul className={styles.list}>
                <li>✔ Aetna</li>
                <li>✔ Anthem Blue Cross and POS</li>
                <li>✔ Blue Shield and POS</li>
                <li>✔ California-Care</li>
                <li>✔ Cigna</li>
                <li>✔ Cigna- Disney Employees</li>
                <li>✔ Health Net and POS</li>
                <li>✔ LA Care</li>
                <li>✔ Lakeside Medical Group</li>
                <li>✔ Molina</li>
                <li>✔ OPTUM</li>
                <li>✔ Pacific-Care</li>
                <li>✔ Regal Medical Group</li>
                <li>✔ Serendib Healthways</li>
                <li>✔ United Health Care</li>
                <li>✔ Preferred IPA*</li>
                <li>✔ Global Care*</li>
                <li>✔ Allied Pacific*</li>
                <li>✔ Community Family Care*</li>
                <li>✔ Easy Care*</li>
              </ul>

              <p className={`${styles.text} mt-4`}>
                <small> *May require transfer</small>
              </p>

              <p className={styles.text}>
                If you belong to another HMO/IPA or have another office listed;
                you can still come into our office and have it all changed to
                one of our office and physicians. Or contact us on{" "}
                <a href="tel:6265175766">626-517-5766</a> and have it changed
                over the phone.
              </p>
            </Container>
          </Col>
          <Col className={styles.c2}>
            <Container className="p-5">
              <h1 className={`pt-5 ${styles.title2}`}>
                I Have PPO/POS insurance
              </h1>
              <ul className={styles.list}>
                <li>✔ Aetna PPO/POS and Managed Choice</li>
                <li>✔ Ameriben</li>
                <li>✔ Anthem Blue Cross of CA</li>
                <li>✔ Blue Shield Of CA</li>
                <li>✔ BlueCard Plans/POS</li>
                <li>
                  ✔ Cigna PPO, Open Access Plus,Disney and International Global
                </li>
                <li>✔ Directors Guild </li>
                <li>✔ Great West Life</li>
                <li>✔ Humana</li>
                <li>✔ HMA</li>
                <li>✔ Health Net PPO/POS</li>
                <li>✔ Keenan-Prime HealthCare</li>
                <li>✔ Motion Picture/ MPI</li>
                <li>✔ Screen Actors Guild/SAG</li>
                <li>✔ Tricare Prime/ChampVA</li>
                <li>✔ Writers Guild</li>
                <li>✔ United Healthcare PPO/POS</li>
              </ul>
              <p className={`${styles.text} mt-4`}>
                <strong>
                  Ask us about our Commercial Group Discount Plan! Call us on{" "}
                  <a href="tel:8183615437">(818) 361-5437</a>
                </strong>{" "}
              </p>
              <p className={styles.text}>
                If you belong to another HMO/IPA or have another office listed;
                you can still come into our office and have it all changed to
                one of our office and physicians. Or contact us on 626-517-5766
                and have it changed over the phone.
              </p>
            </Container>
          </Col>
        </Row>
      </Container> */}

      <Container fluid className={`${styles.c3} p-5`}>
        <Row className={`my-5 g-5 align-items-center `}>
          <Col lg={4} className="mx-auto p-5">
            <h1 className={`${styles.title2}`}>
              {intl.formatMessage({ id: "InsuranceTitle7" })}
            </h1>
            <ul className={styles.list}>
              <li>✔ {intl.formatMessage({ id: "Insurance6" })}</li>
            </ul>
            <p className={styles.text}>
              {intl.formatMessage({ id: "Insurance5" })}
              <a className={styles.link} href="tel:6265175766">
                {" "}
                <strong>626-517-5766</strong>{" "}
              </a>
              {intl.formatMessage({ id: "Insurance5Sub" })}
            </p>
          </Col>

          <Col lg={4} className="mx-auto p-5">
            <h1 className={`${styles.title2}`}>
              {intl.formatMessage({ id: "InsuranceTitle8" })}
            </h1>
            <p className={styles.sub}>
              {intl.formatMessage({ id: "Insurance7" })}
            </p>
            <p className={styles.text}>
              {intl.formatMessage({ id: "Insurance6" })}{" "}
              <a className={styles.link} href="tel:6265175766">
                {" "}
                <strong>626-517-5766</strong>{" "}
              </a>
              {intl.formatMessage({ id: "Insurance6Sub" })}
            </p>
          </Col>

          <Col lg={4} className="mx-auto p-5">
            <h1 className={`${styles.title2}`}>
              {intl.formatMessage({ id: "InsuranceTitle9" })}
            </h1>
            <p className={styles.sub}>
              {intl.formatMessage({ id: "Insurance8" })}
            </p>
            <p className={styles.text}>
              {intl.formatMessage({ id: "Insurance6" })}
              <a className={styles.link} href="tel:6265175766">
                {" "}
                <strong>626-517-5766</strong>{" "}
              </a>
              {intl.formatMessage({ id: "Insurance6Sub" })}
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row xs={1} md={2}>
          <Col>
            <div className={`${styles.content2} mx-auto p-5`}>
              <h1 className={styles.title3}>
                {intl.formatMessage({ id: "Insurance9" })}
              </h1>
              <p className={`${styles.text} my-4`}>
                {intl.formatMessage({ id: "Insurance9Sub" })}
              </p>
            </div>
          </Col>

          <Col className="p-0">
            <StaticImage
              src="../../images/insurance/insurance-2.jpg"
              layout="full_width"
              className={styles.img}
              alt=""
            />
          </Col>
        </Row>
      </Container>

      <Container>
        {/* Accordion Section */}
        <Row className="my-5 py-5 g-5 align-items-center">
          <Col>
            <h1 className={`mb-4`}>
              {intl.formatMessage({ id: "InsuranceTitle10" })}
            </h1>
            <p className={`mb-5`}>
              {intl.formatMessage({ id: "Insurance10" })}
            </p>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {intl.formatMessage({ id: "InsuranceTitle11" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "Insurance11" })}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  {intl.formatMessage({ id: "InsuranceTitle12" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "Insurance12" })}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {intl.formatMessage({ id: "InsuranceTitle13" })}
                </Accordion.Header>
                <Accordion.Body>
                  {intl.formatMessage({ id: "Insurance13" })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
