// extracted by mini-css-extract-plugin
export var blue = "insurance-module--blue--6kV7f";
export var c1 = "insurance-module--c1--bo0fS";
export var c2 = "insurance-module--c2--8D5Ed";
export var c3 = "insurance-module--c3--EH3vt";
export var c4 = "insurance-module--c4--VpCQp";
export var c5 = "insurance-module--c5--rp1Is";
export var content = "insurance-module--content--xBRVN";
export var content2 = "insurance-module--content2--KDyeW";
export var content3 = "insurance-module--content3--V+Y5i";
export var img = "insurance-module--img--SD3h1";
export var imgs = "insurance-module--imgs--CNKzF";
export var link = "insurance-module--link--k+MXm";
export var list = "insurance-module--list--SO7wV";
export var sub = "insurance-module--sub--aLfBa";
export var text = "insurance-module--text--mtBv6";
export var title = "insurance-module--title--tXsXf";
export var title2 = "insurance-module--title2--j0lm6";
export var title3 = "insurance-module--title3--vbWsh";
export var wrapper = "insurance-module--wrapper--wSSsP";
export var wrapper2 = "insurance-module--wrapper2--DfxL7";